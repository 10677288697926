// Pre-load hover images to avoid the initial flash (if "disable cache" is off)
body:after{
  display:none;
  content:
          url(/assets/google-login-sprite-dark-pressed.png)
          url(/assets/NCCE_SW_015.jpg)
          url(/assets/NCCE_SW_019.jpg)
          url(/assets/isaac-cs-typer-css.svg)
;
  @media only screen and (min-resolution: 192dpi) {
    content: url(/assets/google-login-sprite-dark-pressed@2x.png);
  }
}
