// ISAAC
.question-panel, .question-panel > .examboard-special-tabs {
  > .content-chunk > .content-value {
    background-color: white;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);
    margin: auto auto 2rem auto;
    padding: 3rem;
    @include media-breakpoint-down(sm) {
      padding: 1rem;
    }

    font-family: $secondary-font;
    font-size: 1.25rem;
    line-height: 1.6;
    font-weight: 600;
  }
}

.unit-dropdown button {
  min-width: auto;
}

button {
  -webkit-appearance: none;
  border: none;
  outline: none !important;
}

.question-actions {

  &.question-actions-leftmost {
    margin-left: auto;
  }

  .question-actions-icon {
    float: right;
    height: 3rem;

    &:focus {
      outline: none;
    }
  }

  .question-actions-link-box {
    float:right;
    border: 1px solid gray;
    height: 3.6rem;
    border-right: none;
    padding-right: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: -1.9rem;
    background: white;

    .question-actions-link {
      padding-left: 3px;
      padding-right: 3px;
      margin-left: 1rem;
      font-style: italic;
    }
  }
}

.question-component {
  margin-bottom: 2rem;
  clear: both;

  .question-content {
    font-family: $secondary-font;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.6;
  }

  .custom-control-label {
    &:before, &:after {
      width: 1.5rem;
      height: 1.5rem;
      margin-top: -0.25rem;
    }
  }
}

.numeric-question {
  .numeric-help {
    min-width: 4rem !important;
  }

  .unit-selection {
    .dropdown-menu {
      width: 100%;
      min-width: unset;
    }
    button.btn {
      padding: 0.25rem 1.5rem;
      min-width: unset;
      width: 100%;
      &.selected {
        border-radius: 0;
      }
      &.dropdown-toggle::after {
        position: absolute;
        right: 0.5rem;
        top: 1rem;
      }
    }
  }
}

.symbolic-question, .equality-page {
  .eqn-editor-preview {
    border: solid 1px $black;
    padding: 0.15em 0.6em;
    font-size: 1.5rem;
    cursor: default;

    &.empty {
      color: $gray-500;
    }
  }

  .equality-page {
    .eqn-editor-preview {
      background: $white !important;
    }
  }

  .eqn-editor-input input {
    font-family: monospace;
    height: auto;
  }

  .eqn-editor-help {
    min-width: 0;
  }

  .eqn-editor-symbols {
    margin: 10px 0;

    & pre {
      display: inline;
      white-space: pre-wrap;
      background: inherit;
      border: 0;
      padding: 0;
    }
  }
}

#inequality-help {
  min-width: 0;
}

.parsons-question {
  .parsons-items {
    border: solid 1px #00000021;
    padding: 0 0.5em;

    &.empty {
      padding: 0.875em 0.5em;
      &.drag-over {
        padding-top: 1.1em;
        padding-bottom: 1.1em;
      }
    }

    .parsons-item > pre {
      margin: 0.5rem 0;
      padding: 0.5em 1em;
      cursor: grab; // Doesn't work?
    }

    .reorder-item {
      > * {
        overflow-x: auto;
        margin: 0.5rem 0;
        padding: 0.5em 1em;
        cursor: grab;
        background: white;
        border: solid 1px #00000021;
      }
    }

    // REMINDER: If you change the PARSONS_MAX_INDENT and PARSONS_INDENT_STEP
    // constants, you also have to change these two in here.
    $parsons-max-indent: 3;
    $parsons-step: 45;

    .parsons-item {
      @for $i from 0 through $parsons-max-indent {
        &.indent-#{$i} {
          margin-left: #{$i * $parsons-step}px;
        }
      }

      position: relative;

      .controls {
        display: none;
      }

      .show-controls {
        .controls {
          position: absolute;
          right: 0.5rem;
          top: 0.5rem;
          display: block;

          span {
            display: inline-block;
            width: 20px;
            height: 20px;
            cursor: pointer;

            &.show:hover {
              background-color: $primary !important;
            }

            &.hide {
              opacity: 0.2;
              cursor: default;
            }

            &.show {
              opacity: 1.0;
            }

            &.reduce {
              background: $gray-120 url('/assets/chevron_left.svg') no-repeat center;
              margin-right: 4px;
            }

            &.increase {
              background: $gray-120 url('/assets/chevron_right.svg') no-repeat center;
            }
          }
        }
      }
    }

    &#parsons-choice-area {
      @for $i from 0 through $parsons-max-indent {
        &.ghost-indent-#{$i} {
          background: linear-gradient(to right,
            white #{$i * $parsons-step + 5}px, $gray-400 #{$i * $parsons-step + 5}px, $gray-400 #{$i * $parsons-step + 7}px, white #{$i * $parsons-step + 7}px
          );
        }
      }

      &.is-dragging {
        .parsons-item {
          opacity: 0.666;
        }
      }
    }
  }
}

.inequality-results {
  pre {
    white-space: pre-wrap;
  }
}

.quick-question {
  button {
      box-shadow: none !important; // How horrible...
  }
  .alert {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.validation-response-panel {
  text-align: center;
  border-top: solid 1px $gray-107;
  border-bottom: solid 1px $gray-107;
  &.correct {
    background: $primary;
    color: black;
    margin-left: -3rem;
    margin-right: -3rem;
    margin-bottom: -3rem;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    @include media-breakpoint-down(sm) {
      margin-left: -1rem;
      margin-right: -1rem;
      margin-bottom: -1rem;
    }
  }
  p {
    margin-bottom: 0;
  }
}

// NOMENSA question.scss
.question-component {
  background-color: white;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);
  padding: 1rem;
}

.cloze-question {
  // This is a work around, allowing KaTeX to be used for cloze questions.
  //  There is no guarantee that it works all of the time, so needs to be
  //  checked on a case by case basis.
  .katex {
    display: unset;
    * {
      pointer-events: none;
    }
    .cloze-item {
      pointer-events: all;
    }
  }
  .cloze-item {
    pointer-events: all;
    .katex-display {
      display: unset;
    }
  }
}

.item-section {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: center;
  // gridAutoRows: "max-content",
  // gridTemplateColumns: "repeat(2, 1fr)",
  min-height: 64px;
}

.cloze-item {
  touch-action: none;
  position: relative;
  font-size: 1rem !important;
  &.is-dragging {
    opacity: 0.666;
  }
}

.cloze-drop-zone {
  &.incorrect {
    filter: drop-shadow(0 0 0.3rem red);
  }
}

.feedback-zone {
  position: relative;
  &.feedback-showing {
    padding-right: 34px !important;
  }
  .feedback-box {
    text-align: center;
    position: absolute;
    right: 3px;
    top: 3px;
    color: black;
    border-radius: 2.5px; //
    height: calc(100% - 6px);
    padding-left: 6px;
    padding-right: 6px;
    background-color: white;
    .feedback {
      position: relative;
      display: inline-block;
      top: 50%;
      transform: translateY(-50%);
      &.correct {
        color: green;
      }
      &.incorrect {
        color: red;
      }
    }
  }
  &.nq-feedback {
    input {
      background-image: none !important;
    }
    .feedback-box {
      z-index: 3;
      right: 75px;
    }
  }
}

// Could be used to display draggables when using drop zones in text or code (if we got rid of the purple blocks)
//.cloze-item & .minimal {
//  text-decoration: underline dashed $secondary;
//}

// Could be used to outline draggable pieces of text
//.cloze-item & .minimal:after {
//  position: absolute;
//  left: 50%;
//  top: 50%;
//  transform: translate(-50%, -50%);
//  height: 120%;
//  display: block;
//  width: max(120%, 2rem);
//  background: transparent;
//  border: 2px solid red;
//  content: '';
//}
