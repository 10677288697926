@mixin table-sticky() {
  position: sticky !important;
  background-clip:  padding-box !important;
  background: $gray-103;
  z-index: 1;
  top: 0;
}

@mixin after-border() {
  content:'';
  position:absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
}

.list-gameboard {
  .bg-success {
    background-color: #dfefd7 !important;
    a > span:first-of-type {
      border-color: #ddd;
    }

    a {
      // Slightly higher contrast colours to achieve 4.5 contrast ratio with light green background
      .maths > .text-secondary {
        color: #017195 !important;
      }
      .physics > .text-secondary {
        color: #8f49b8 !important;
      }
      .chemistry > .text-secondary {
        color: #cb2921 !important;
      }
      // Biology green has a 7.88 contrast ratio against the light green background
      .biology > .text-secondary {
        color: #005210 !important;
      }
    }
  }

  .gameboard-item-icon {
    max-width: 2rem;
    max-height: 2rem;
  }

  .gameboard-item-message {
    font-size: 0.875rem;
    font-weight: normal;
    color: $gray-160;
    align-self: center;
    padding-left: 1.5rem;
  }

  .gameboard-item-message-phy {
    font-size: 0.875rem;
    font-weight: normal;
    color: #000000;
    align-self: center;
    padding-left: 1.5rem;
    text-transform: uppercase;
  }
}

.hierarchy-tags {
  font-size: 0.875rem;
  color: $gray-160;
  align-self: center;

  .hierarchy-tag:not(:first-child) {
    padding-left: 12px;
    &::before {
      background-image: url(/assets/chevron_right.svg);
      background-repeat: no-repeat;
      background-size: contain;
      content: ' ';
      height: 10px;
      margin-left: -9px;
      margin-top: 6px;
      width: 10px;
      position: absolute;
    }
  }
}

.modal-body {
  .basic-multi-select {
    .select__control.select__control--is-focused {
      border-color: $primary;
      box-shadow: 0 0 0 0.2rem $shadow-08;
    }

    .select__menu {
      z-index: 2;
      .select__option.select__option--is-focused {
        background-color: $primary;
      }
    }
  }
}

.responsive {
  overflow: auto;
  display: block;
  max-height: 60vh;
  .table.table-bordered {
    thead {
      @include table-sticky();
    }
    tr {
      &.disabled {
        background-color: lightgrey;
      }

      &.selected {
        background-color: lightyellow;
      }

      td {
        .custom-control-label {
          &:before, &:after {
            width: 1.5rem;
            height: 1.5rem;
            margin-top: -0.25rem;
          }
        }

        .img-center {
          display: flex;
          justify-content: center;
          input {
            height: 2rem;
          }
        }
      }
    }
  }
}
