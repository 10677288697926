.stage-label-gcse {
  background-color: $gcse-colour;
  color: white;
}

.stage-label-alevel {
  background-color: $a-level-colour;
  color: white;
}

.stage-label-all {
  background-color: $all-stages-colour;
  color: white;
}
