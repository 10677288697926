@import "../common/questions";

.validation-response-panel {
  text-align: left;
}

.quick-question-title {
  display: flex;
  justify-content: center;
}
