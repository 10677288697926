.quiz-list {
  font-family: $secondary-font-medium;
  background-color: $white;
  border: 1px solid $gray-107;
  border-bottom: 1px solid $gray-107;
  font-size: 1.25rem;

  > li {
    display: flex;
    border-bottom: 1px solid $gray-107;

    .quiz-list-separator {
      border-left: 1px solid $gray-107;
      &:after {
        @include pseudo-element;
        background-image: url('/assets/chevron_right.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: center;
        height: 100%;
        right: 10px;
        top: 0;
        bottom: 0;
        width: 9px;
      }
    }
  }
}

.quiz-marks-table td {
  padding-left: 16px;
}

.quiz-student-menu {
  position: relative;
  padding-right: 24px;
  cursor: pointer;
}
.quiz-student-menu-icon {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -12px;
}
.quizFeedbackModeSpinner {
  vertical-align: middle;
}
