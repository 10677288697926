.bg-grey {
  background: rgba($gray-120, 0.6);
}

.icon-person-active {
  float: left;
  width: 9px;
  height: 14px;
  background: url(/assets/ru.png) -296px -302px no-repeat;
  margin: 3px 10px 0 0;
}
.icon-person {
  float: left;
  width: 9px;
  height: 14px;
  background: url(/assets/ru.png) -296px -316px no-repeat;
  margin: 0 10px 0 0;
}
.icon-warning {
  float: left;
  width: 19px;
  height: 22px;
  background: url(/assets/ru.png) -308px -302px no-repeat;
  margin-left: 15px;
  margin-right: 12px;
}
.icon-help {
  display: inline-block;
  width: 19px;
  height: 22px;
  background: url(/assets/ru.png) -308px -324px no-repeat;
  margin-bottom: -4px;
  margin-left: 8px;
  margin-right: 8px;
}
.icon-group {
  float: left;
  width: 23px;
  height: 19px;
  background: url(/assets/ru.png) -330px -321px no-repeat;
  margin: 10px 10px 10px 0;
}
.icon-group-table-person {
  float: left;
  width: 11px;
  height: 18px;
  background: url(/assets/ru.png) -357px -320px no-repeat;
  margin: 8px 10px 0 0;
}
.icon-email-status {
  display: inline-block;
  width: 25px;
  height: 19px;
  margin-left: 15px;
  margin-bottom: -7px;
  background: url(/assets/email-status.png) no-repeat;
  background-size: 75px;
}
.icon-email-status.unverified {
  background-position: -25px 0px;
}
.icon-email-status.failed {
  background-position: -50px 0px;
}

polygon.fill-secondary {
  fill: $secondary;
}
