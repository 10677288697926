#filter-panel {
  margin-bottom: 42px;
  #expand-filter-button {
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    background-color: $secondary;
    -webkit-mask-image: url(/assets/reveal-down.svg);
    mask-image: url(/assets/reveal-down.svg);
    &.open {
      -webkit-mask-image: url(/assets/reveal-up.svg);
      mask-image: url(/assets/reveal-up.svg);
    }
    display: block;
    height: 34px;
    width: 40px;
    left: 50%;
    transform: translate(-50%, 0); // Centers the button correctly
    bottom: -34px;
    position: absolute;
    background-position: 50% top;
    background-repeat: no-repeat no-repeat;
    cursor: pointer;
    z-index: 0;
  }
  .filter-go-to-questions {
    color: $secondary;
    display: block;
    text-align: center;
    position: absolute;
    transform: translate(-50%, 0);
    bottom: 0;
    left: 50%;
    border: 1px solid $secondary;
    border-bottom: none;
    padding: 0.25rem 0.75rem;
    border-radius: 10px 10px 0 0;
    font-weight: bold;
  }
  .filter-action {
    text-decoration: underline;
    &:hover, &:focus {text-decoration: none;}
  }
}

.hexagon-tier-title, .hexagon-level-title, .hexagon-tier-summary, .difficulty-title {
  pointer-events: none;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: $secondary-font-semi-bold;
}
.hexagon-level-title {
  font-weight: 600;
  font-size: 1rem;
  &.active {color: white;}
}
.hexagon-tier-title {
  //font-weight: 600;
  font-size: 0.8rem;
  color: #333;
  hyphens: manual;
  &.active {color: white;}
  &.small {
    font-size: 0.75rem;
    overflow-wrap: anywhere;
  }
  &.de-emph {
    color: #33333344;
  }
}
.hexagon-tier-summary {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  font-weight: 100;
}

.difficulty-title {
  font-weight: 400;
  font-size: 0.92rem;
  &.active {color: white;}
  &.difficulty-icon-title {
    font-weight: 600;
    font-size: 0.8rem;
  }
}

